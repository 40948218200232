.App {
	width: 100%;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
	font-family: "Poppins", sans-serif;
	overflow-x: hidden;
}

a,
a:hover,
a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;
}
.dropdown-menu {
	background-color: #0c0d0e !important;
	color: white !important;
	border-radius: 6px;
}
.dropdown-item {
	color: white !important;
}
.dropdown-item:hover {
	color: black !important;
}
