.login-form {
	display: flex;
	flex-direction: column;
	/* margin-block: auto; */
	/* align-items: center;
   */
	/* align-content: center; */
	/* flex-direction: column; */
	/* align-items: center; */
}

.login-header {
	font-size: 2rem !important;
}

.login-form .submit-btn {
	border-radius: 3px;
	border: none;
	color: rgb(237, 236, 236);
	background-color: rgb(79, 77, 78);
}
