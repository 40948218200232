@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
html {
	background-color: #0c0d0e !important;
}
body {
	color: #e8e6e3;
	margin: 0;
	background-color: #0c0d0e !important;
	color: #e8e6e3 !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.App {
	width: 100%;
}

body {
	font-family: "Poppins", sans-serif;
	overflow-x: hidden;
}

a,
a:hover,
a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;
}
.dropdown-menu {
	background-color: #0c0d0e !important;
	color: white !important;
	border-radius: 6px;
}
.dropdown-item {
	color: white !important;
}
.dropdown-item:hover {
	color: black !important;
}

.toc_child {
	display: none;
}

a {
	text-decoration: none;
	color: rgb(84, 159, 230);
}

a:hover {
	color: rgb(125, 182, 236);
}

div {
	color: #e8e6e3;
}

/* .toc ul {
  display: flex;
  /* list-style-type: none; */
/* flex-wrap: wrap; */
/* }  */
/* .toc li {
  margin-right: 16px;
} */
h1 {
	font-size: 1.55rem !important;
}

h2 {
	font-size: 1.45rem;
}

.user-box {
	display: flex;
	justify-content: center;
	position: relative;
}
svg :hover,
path :hover {
	cursor: pointer;
}
.user-box input {
	width: min(95vw, 30rem);

	padding: 10px 0;
	font-size: 16px;
	color: #fff;
	margin-bottom: 30px;
	border: none;
	border-bottom: 1px solid #fff;
	outline: none;
	background: transparent;
}

.user-box label {
	position: absolute;
	top: 0;
	left: 0;
	padding: 10px 0;
	font-size: 16px;
	color: #fff;
	pointer-events: none;
	transition: 0.5s;
}
.user-box input:focus ~ label,
.user-box input:valid ~ label {
	top: -20px;
	left: 0;
	color: #90f6ff;
	font-size: 12px;
}

.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 48px;
	width: min(95vw, 30rem);
	padding: 0 8px;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 6px;
}
.tags-input input {
	flex: 1 1;
	border: none;
	/* height: 46px; */
	font-size: 14px;
	padding: 4px 0 0 0;
}

.tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	border-radius: 20px;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	color: #fff;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 15px;
	margin: 0 8px 8px 0;
	background: #27292b;
}

.tag-close-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	font-size: 14px;
	margin-left: 8px;
	color: #0052cc;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
}

.guide-item {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.guide-item:hover {
	background-color: #080808 !important;
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px #161616;
	-webkit-transform: scale(0.25, 0.25);
	transform: scale(1.03, 1.03);
}

.guide-item::after {
	content: "";
	border-radius: 5px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	opacity: 0;
	transition: all 0.6s cubic-bezier(0.165, 0.4, 0.4, 1);
}

.guide-item:hover::after {
	opacity: 1;
}

.select__control,
.select__multi-value {
	background-color: black !important;
	color: white !important;
}
.select__control div {
	background-color: black !important;
	color: white !important;
}

.select__input,
.select__multi-value__label {
	color: white !important;
}

.login-form {
	display: flex;
	flex-direction: column;
	/* margin-block: auto; */
	/* align-items: center;
   */
	/* align-content: center; */
	/* flex-direction: column; */
	/* align-items: center; */
}

.login-header {
	font-size: 2rem !important;
}

.login-form .submit-btn {
	border-radius: 3px;
	border: none;
	color: rgb(237, 236, 236);
	background-color: rgb(79, 77, 78);
}

.logout {
	border-radius: 4px;
	background-color: rgb(66, 65, 65);
}
.logout :hover {
	cursor: pointer;
}

